<template>
  <div class="flex flex-col position-relative" :class="{'md-access-denied-height': accessDenied === true}">
    <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
    <div class="w-full flex justify-between">
      <div class="md:w-1/4">
        <b-form-input
            @input="search"
            placeholder="Sök"
        />
      </div>
      <div class="flex justify-end">
        <b-button
            v-on:click="openModalFee('add',null)"
            size="sm"
            class="p-1 bg-button "
            variant="outline-primary"
            v-text="$t('Fees.createFee')"
        />
      </div>
    </div>
    <b-card class="mt-3">
      <b-card-text>
        <div class="scrol-area table-responsive">
          <div v-if="loadingTable" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>{{ $t('Message.loading') }}...</strong>
          </div>
          <table v-else class="table b-table table-hover">
            <thead class="">
            <tr>
              <!-- <th class="text-left whitespace-nowrap p-2 md:p-0">
                  <span class="text-xs text-gray-400">
                      <b-form-checkbox
                          v-model="selected"
                          @change="performAllChecked"
                          :value="checked"
                          class="custom-control-primary my-auto"
                      />
                  </span>
              </th> -->
              <th v-for="(item, i) in fields" :key="i" class="text-center whitespace-nowrap p-2 md:p-0">
                <div class="flex justify-between gap-5 items-center w-full">
                  <span class="text-xs text-gray-400">{{ $t(item.label) }}</span>
                  <!-- <span class="flex flex-col">
                      <svg @click="sortColumn(item)" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg @click="sortColumn(`-${item}`)" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                  </span> -->
                </div>
              </th>
              <!-- <th class="text-center whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span></th> -->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,i) in items.results" :key="data.uuid" v-on:click="openModalFee('update',data,i)">
              <!-- <td>
                  <span>
                      <b-form-checkbox @change="performSingleChecked" v-model="data.checkbox" />
                  </span>
              </td> -->

              <td v-for="(item, i) in fields" :key="i">
                <span class="whitespace-nowrap cursor-pointer"
                      v-if="item.key === 'accounting_article'">{{ data[item.key].article_number }}</span>
                <span class="whitespace-nowrap cursor-pointer" v-else-if="item.key === 'golf_article_type'">{{
                    data[item.key] ? data[item.key].name : null
                  }}</span>
                <span class="whitespace-nowrap cursor-pointer" v-else-if="item.mode === 'date'">{{
                    data[item.key].split('T')[0]
                  }}</span>
                <!-- data[item.key].description ? data[item.key].description : null  -->
                <span class="cursor-pointer" v-else>
                                        <span class="whitespace-nowrap"
                                        >{{ data[item.key] ? data[item.key] : null }}</span>
                </span>
              </td>
              <!-- <td>
                  <div
                      class="d-flex justify-content-between smallGap align-items-center px-2"
                  >
                  </div>
              </td> -->
            </tr>
            </tbody>
          </table>
        </div>

        <div
            class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
        >
          <paginate
              v-model="pagination.currentPage"
              :page-count="pagination.totalPages"
              :click-handler="firePagination"
              :prev-text="'<'"
              :next-text="'>'"
              :page-class="'page-item c-mr-1 border-unset'"
              prev-link-class='page-link c-mr-1 border-unset'
              next-link-class='page-link c-mr-1 border-unset'
              next-class='next-item'
              prev-class='prev-item'
              page-link-class='page-link c-mr-1 border-unset'
              active-class='active c-mr-1 border-unset'
              :container-class="'pagination  b-pagination'">
          </paginate>
          <!-- <b-pagination
              v-model="pagination.currentPage"
              :total-rows="pagination.totalProducts"
              :per-page="queryParams.limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
              prev-class="prev-item"
              next-class="next-item"
          >
              <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
          </b-pagination> -->
        </div>
      </b-card-text>
    </b-card>
    <CustomNoHeaderModalVue v-if="openModal" width="w-1/2 md:w-1/4" :title="''" @handleClose="openModal = false"
                            :close="!true">
      <div class="flex flex-col" slot="header">
        <div class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1">
          <div class="flex flex-col">
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900" v-if="actionType === 'add'">
              {{ $t('Fees.createFee') }}</h2>
            <h2 class="text-xl md:text-2xl font-semibold font-poppins text-gray-900" v-else>{{
                $t('Fees.updateFee')
              }}</h2>
          </div>
        </div>
      </div>
      <div class="flex flex-col" slot="body">
        <div class="mb-1">
          <div class="flex flex-col mt-2">
            <ValidationObserver ref="form">
              <div class="grid grid-cols-1 md:grid-cols-1 gap-1">
                <div class="">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        :name="'article_number'"
                        class="w-full"
                    >
                      <b-form-group :label="$t('ArticleSegment.articleNumber')" class="w-full">
                        <v-select
                            class="w-full"
                            label="article_number"
                            v-model="emptyArticle"
                            @option:selected="setArticle($event)"
                            :filterable="false"
                            :options="articleResults.results"
                            @search="searchArticle"
                        >
                          <template slot="no-options"> Sök...</template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.article_number }} {{ option.description }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.article_number }} {{ option.description }}
                            </div>
                          </template>
                        </v-select>
                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </validation-provider>

                  </b-form-group>
                </div>
                <div class="">
                  <b-form-group>
                    <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        :name="'description'"
                        class="w-full"
                    >
                      <b-form-group :label="$t('ArticleSegment.articleDesc')" class="w-full">
                        <b-form-input v-model="selectedItem.article_description"/>
                        <small class="text-danger" v-text="errors[0]"/>
                      </b-form-group>
                    </validation-provider>

                  </b-form-group>
                </div>
                <div class="">
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required'"
                      :name="'description'"
                      class="w-full"
                  >
                    <b-form-group :label="$t('golfRelatedCard.table.golfArticleType')">
                      <v-select
                          class="w-full"
                          label="name"
                          v-model="fee.name"
                          @option:selected="setFeeType($event)"
                          :filterable="false"
                          :options="feeTypesResult.results"
                          @search="searchFeeTypeSelect"
                      >
                        <template slot="no-options"> Sök...</template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="w-full flex justify-end mt-2">
                  <b-button
                      :disabled="processing"
                      size="md"
                      variant="success"
                      v-text="processing ? $t('Message.loading') : actionType === 'add' ? $t('Fees.createFee') : $t('Fees.updateFee')"
                      @click="processFeeType"
                  />
                </div>
              </div>

            </ValidationObserver>
          </div>
        </div>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import Paginate from 'vuejs-paginate'
import {ValidationProvider, ValidationObserver} from "vee-validate";
import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import CustomNoHeaderModalVue from '@/@core/customComponent/CustomNoHeaderModal.vue';
import vSelect from "vue-select";
import {mapGetters} from "vuex"

export default {
  name: 'Overview',
  components: {
    'paginate': Paginate, mdAccessDenied, CustomNoHeaderModalVue, ValidationProvider,
    ValidationObserver, vSelect
  },
  data() {
    return {
      items: {results: []},
      feeTypesResult: {results: []},
      articleResults: {results: []},
      loadingTable: false,
      processing: false,
      accessDenied: false,
      openModal: false,
      queryParams: {
        limit: 30,
      },
      pagination: {
        currentPage: 1,
        totalPages: 1,
      },
      fields: [
        {
          key: 'accounting_article',
          label: 'ArticleSegment.articleNumber',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 10px',
            width: '70px',
          },
        },
        {
          key: 'article_description',
          label: 'ArticleSegment.articleDesc',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '200px',
          },
        },
        {
          key: 'golf_article_type',
          label: 'golfRelatedCard.table.golfArticleType',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '150px',
          },
        },
        {
          key: 'created_at',
          mode: 'date',
          label: 'golfRelatedCard.table.creationDate',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '80px',
          },
        },
        {
          key: 'updated_at',
          mode: 'date',
          label: 'golfRelatedCard.table.updatedAt',
          tdClass: 'bTableThStyle',
          thStyle: {
            padding: '0.72rem 0',
            width: '80px',
          },
        },
      ],
      form: {
        name: '',
        description: '',
      },
      actionType: 'add',
      emptyCate: '',
      emptyArticle: '',
      article: {},
      fee: {
        "name": "",
        "uuid": null
      },
      selectedItem: {
        article_description: '',
        article_number: '',
      },
      editIndex: null
    }
  },
  mounted() {
    this.getFees()
    this.getFeeType()
    this.fetchArticle()
  },
  computed: {
    message: function () {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    processFeeType: function () {
      let payload = {
        article_number: this.article.article_number,
        article_description: this.selectedItem.article_description,
        golf_article_type_uuid: this.fee.uuid,
      }
      for (const [key, value] of Object.entries(payload)) {
        if (value === undefined || value === '' || value === null) {
          this.popupMsg(
              this.$t('Message.Failed'),
              `${key.replace('_uuid', '').replace('_', ' ').toUpperCase()} Field required`,
              'AlertTriangleIcon',
              'danger'
          );
          return false

        }
      }

      let mode = ''
      if (this.actionType === 'add') {
        mode = {URL: '/', method: 'post'}
      } else {
        mode = {URL: `/${this.selectedItem.uuid}/`, method: 'put'}
      }
      this.processing = true
      this.$useJwt.getFees(mode, payload)
          .then(res => {
            // this.items = res.data.data
            this.processing = false
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
            this.openModal = false
            this.getFees()

          })
          .catch(err => {
            this.processing = false
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          })
    },

    firePagination(number) {
      this.pagination.currentPage = number
      this.queryParams.page = number;
      this.getFees();
    },
    getFees(init = null) {
      this.accessDenied = false
      const mode = init ? init : {URL: '/', method: 'get'}
      this.$useJwt.getFees(mode, {params: this.queryParams})
          .then(res => {
            this.loadingTable = false
            this.items = res.data.data
            if (res.data.data.count > this.queryParams.limit) {
              this.pagination.totalPages = Math.ceil(res.data.data.count / this.queryParams.limit)
            } else {
              this.pagination.totalPages = 1
            }
          })
          .catch(err => {
            this.loadingTable = false
            if (err.response.status === 403) {
              this.accessDenied = true
            } else {
              this.accessDenied = false
            }
          })
    },
    searchFeeTypeSelect(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteFeeType(loading, search, this);
      }
    },
    setFeeType: function (item) {
      this.emptyCate = item.name
      this.fee = item
    },
    setArticle: function (item) {
      this.emptyArticle = item.article_number
      this.selectedItem.article_description = ''
      this.article = item
      if (this.actionType === 'add') {
        this.article.description = ''
      }


    },
    searchArticleSelect(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteFeeType(loading, search, this);
      }
    },
    searchArticle: debounce(function (loading, search, vm) {
      const query = {search: escape(search)};
      this.$useJwt
          .getArticles({URL: "/", method: "get"}, {params: query})
          .then((res) => {
            this.articleResults = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchArticle: function () {
      this.$useJwt
          .getArticles({URL: "/", method: "get"})
          .then((res) => {
            this.articleResults.results = res.data.data;
          })
          .catch((err) => {
          });
    },
    searchRemoteFeeType: debounce(function (loading, search, vm) {
      const query = {search: escape(search)};
      this.$useJwt
          .getFeesTypes({URL: "/", method: "get"}, {params: query})
          .then((res) => {
            this.feeTypesResult = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    getFeeType(init = null) {
      const mode = init ? init : {URL: "/", method: "get"};
      this.$useJwt.getFeesTypes(mode).then((res) => {
        this.feeTypesResult = res.data.data;
      }).catch(err => {
      });
    },
    openModalFee: function (type, data = null, index = null) {
      this.actionType = type

      if (type === 'add') {
        this.selectedItem.article_description = ''
        this.selectedItem.article_number = ''
        this.selectedItem.uuid = ''
        this.emptyCate = ''
        this.emptyArticle = ''
        this.article = {}
        this.editIndex = null
        Object.keys(this.fee).map(k => {
          this.fee[k] = ''
        })
      } else {
        // this.selectedItem = data
        if (data.golf_article_type) {
          this.fee = JSON.parse(JSON.stringify(data.golf_article_type))
        } else {
          Object.keys(this.fee).map(k => {
            this.fee[k] = ''
          })
        }
        this.selectedItem = JSON.parse(JSON.stringify(data))
        this.emptyArticle = data.article_number
        this.emptyCate = data.golf_article_type
        this.article.article_number = data.article_number
        this.article.description = data.article_description
        this.editIndex = index
      }
      this.openModal = true
    },
    search: debounce(function (e) {
      if (e) {
        this.getFees({URL: '/?search=' + escape(e), method: 'get'})
      } else {
        this.getFees()
      }
    }, 350),
  }

}
</script>

<style lang="scss" scoped>
.table th {
  padding: .4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.bg-button {
  background: #16149F;
  border: 1px solid #16149F !important;
  color: #ffffff;
}

.table tbody, td {
  padding: .4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>