<template>
  <div class="flex flex-col position-relative" :class="{'md-access-denied-height': accessDenied === true}">
    <md-access-denied :is-access-denied="accessDenied" v-if="accessDenied === true" class="mt-40"/>
    <div class="md:w-1/4">
      <b-form-input @input="search" placeholder="Sök"/>
    </div>
    <b-card class="mt-3">
      <b-row>
        <b-col
            v-for="data in items.results"
            :key="data.uuid"
            cols="12"
            sm="6"
            lg="4"
        >
          <div class="custom-border rounded-md p-1 mb-1">
            <b-card-title
                class="d-flex mb-0 justify-content-between align-items-center"
            >
              <p class="mb-0">{{ data.name }}</p>

              <div class="d-flex smallGap">
                <!-- <mdicon
                                        class="text-danger"
                                        role="button"
                                        :width="20"
                                        :height="20"
                                        name="trash-can"
                                    /> -->
                <b-button
                    size="sm"
                    variant="outline-secondaryColor"
                    :disabled="accessDenied"
                    block
                    @click="openModalUpdate(data)"
                    v-text="$t(locale.edit)"
                />
              </div>
            </b-card-title>
            <hr/>
            <b-card-text class="mt-1">
              <div class="d-flex justify-content-between">
                <p class="mb-0 w-50 text-secondaryColor" id="label">
                  BESKRIVNING
                </p>
                <p class="mb-0 w-50">
                  {{ data.description }}
                </p>
              </div>

              <hr/>

              <div class="d-flex justify-content-between">
                <p class="mb-0 w-50 text-secondaryColor" id="label">
                  ANTAL AVGIFTER/ARTIKLAR TILLAGDA
                </p>
                <p class="mb-0 w-50">
                  Består av
                  {{ data.num_present_golf_articles }} artiklar
                </p>
              </div>

              <!-- <hr />

                                <div class="d-flex justify-content-between">
                                    <p
                                        class="mb-0 w-50 text-secondaryColor"
                                        id="label"
                                    >
                                        RECURRING BILLING
                                    </p>
                                    <p class="mb-0 w-50">
                                        In total this segment gets billed 250k
                                        monthly
                                    </p>
                                </div> -->
            </b-card-text>
          </div>
        </b-col>

      </b-row>
      <div class="flex justify-end w-full">
        <paginate
            v-model="feeTypePage.page"
            :page-count="items.total_pages"
            :click-handler="onChangePagination"
            :prev-text="'<'"
            :next-text="'>'"
            :page-class="'page-item c-mr-1 border-unset'"
            prev-link-class="page-link c-mr-1 border-unset"
            next-link-class="page-link c-mr-1 border-unset"
            next-class="next-item"
            prev-class="prev-item"
            page-link-class="page-link c-mr-1 border-unset"
            active-class="active c-mr-1 border-unset"
            :container-class="'pagination  b-pagination'"
        >
        </paginate>
      </div>
    </b-card>

    <CustomNoHeaderModalVue
        v-if="openCreateModalFeeType"
        width="w-11/12 md:w-1/4"
        :title="''"
        @handleClose="openCreateModalFeeType = false"
        :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
            class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          Create Fee Type
        </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="form">
          <div class="flex flex-col">
            <div class="flex w-full" v-if="hidden_fields.name">
              <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  :name="'name'"
                  class="w-full"
              >
                <b-form-group label="Name" class="w-full">
                  <b-form-input v-model="feeTypeForm.name"/>
                  <small class="text-danger" v-text="errors[0]"/>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="flex w-full" v-if="hidden_fields.description">
              <validation-provider
                  #default="{ errors }"
                  :rules="'required'"
                  :name="'description'"
                  class="w-full"
              >
                <b-form-group label="Description" class="w-full">
                  <b-form-input v-model="feeTypeForm.description"/>
                  <small class="text-danger" v-text="errors[0]"/>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="w-full">
              <div class="grid grid-cols-3 gap-1 mb-1">
                <span
                    v-for="(item, i) in selectedArticles"
                    :key="item.uuid"
                    class="flex justify-between gap-2 bg-blue-900 c-p-1 rounded-md text-white"
                >
                  <small>{{ item.accounting_article.article_number }}</small>
                  <span class="cursor-pointer" @click="removeMember(i)">
                    <feather-icon icon="Trash2Icon"/>
                  </span>
                </span>
              </div>
            </div>

            <div class="flex w-full">
              <b-form-group class="w-full" label="Add articles">
                <v-select
                    class="w-full"
                    label="article_number"
                    v-model="emptyCate"
                    @option:selected="setArticle($event)"
                    :filterable="false"
                    :options="articleResults"
                    @search="searchArticleSelect"
                >
                  <template slot="no-options"> Sök...</template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.accounting_article.article_number }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.accounting_article.article_number }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </div>
          </div>
          <div class="w-full flex justify-end mt-2">
            <b-button
                :disabled="processing"
                size="md"
                variant="success"
                v-text="processing ? $t('Message.loading') : 'Create'"
                @click="createFeeType"
            />
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>

    <CustomNoHeaderModalVue
        v-if="openModalFeeType"
        width="w-11/12 md:w-1/2"
        :title="''"
        @handleClose="openModalFeeType = false"
        :close="!true"
    >
      <div class="flex flex-col p-1" slot="header">
        <h2
            class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
        >
          Update Fee Type
        </h2>
      </div>
      <div class="relative flex flex-col p-1 z-50" slot="body">
        <ValidationObserver ref="form">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div class="flex">
              <b-form-group label="Name">
                <b-form-input v-model="feeTypeForm.name"/>
              </b-form-group>
            </div>
            <div class="flex">
              <b-form-group label="Description">
                <b-form-input v-model="feeTypeForm.description"/>
              </b-form-group>
            </div>
          </div>
          <div class="col-span-2 flex flex-col mt-3">
            <div class="flex gap-4 item-center w-full mb-3">
              <h5 v-if="addArticle">Add Articles to fee type</h5>
              <h5 v-if="!addArticle">Remove Articles from fee type</h5>
              <div class="flex flex-col">
                <svg
                    @click="toggle(true)"
                    :class="addArticle ? 'text-theme-sidebar' : ''"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>

                <svg
                    @click="toggle(false)"
                    :class="addArticle ? '' : 'text-theme-sidebar'"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                  <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  />
                </svg>
              </div>
              <span>
                <b-form-input
                    class="w-1/2"
                    placeholder="Sök"
                    @input="searchArticles"
                />
              </span>
            </div>
            <div
                v-if="addArticle"
                class="grid grid-cols-1 md:grid-cols-1 custom-border rounded-md"
            >
              <div class="h-60 overflow-y-scroll">
                <span
                    class="w-full flex justify-center mt-3"
                    v-if="loadingArticle"
                >
                  <b-spinner small type="grow"/>
                  {{ $t("Message.loading") }}...
                </span>
                <table v-else class="table w-full z-50 relative mb-7">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400">
                          <b-form-checkbox
                              v-model="selected"
                              @change="performAllChecked"
                              :value="checked"
                          />
                        </span>
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">Golf type</span>
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">Article</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="article in copyMainArticle" :key="article.uuid">
                    <td>
                        <span>
                          <b-form-checkbox
                              @change="performSingleChecked"
                              v-model="article.checkbox"
                          />
                        </span>
                    </td>
                    <td>
                        <span>{{
                            article.golf_article_type
                                ? article.golf_article_type.name
                                : null
                          }}</span>
                    </td>
                    <td>
                        <span>{{
                            article.accounting_article.article_number
                          }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
                v-if="!addArticle"
                class="grid grid-cols-1 md:grid-cols-1 custom-border rounded-md"
            >
              <div class="h-60 overflow-y-scroll border-l">
                <table class="table w-full z-50 relative mb-7">
                  <thead>
                  <tr>
                    <th class="whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400">
                          <b-form-checkbox
                              v-model="selectedRemoved"
                              @change="performAllRemoveChecked"
                              :value="checked"
                          />
                        </span>
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0">
                        <span class="text-xs text-gray-400"
                        >Artikelnr.</span
                        >
                    </th>
                    <th class="whitespace-nowrap p-2 md:p-0">
                      <span class="text-xs text-gray-400">Name</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(article, i) in finalArticle" :key="i">
                    <td>
                        <span class="flex justify-start">
                          <b-form-checkbox
                              @change="performSingleRemove"
                              v-model="article.checkbox"
                          />
                        </span>
                    </td>
                    <td>
                        <span>{{
                            article.accounting_article_number
                                ? article.accounting_article_number
                                : null
                          }}</span>
                    </td>
                    <td>
                      <span>{{ article.fee_name }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="addArticle" class="w-full flex justify-between mt-2">
            <div class="flex justify-between gap-10">
              <paginate
                  v-model="articlePage"
                  :page-count="1"
                  :click-handler="firePagination"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :page-class="'page-item c-mr-1 border-unset'"
                  prev-link-class="page-link c-mr-1 border-unset"
                  next-link-class="page-link c-mr-1 border-unset"
                  next-class="next-item"
                  prev-class="prev-item"
                  page-link-class="page-link c-mr-1 border-unset"
                  active-class="active c-mr-1 border-unset"
                  :container-class="'pagination  b-pagination'"
              >
              </paginate>
              <div>
                <b-button
                    v-if="markArticles.length > 0"
                    @click="updateFeeType('ADD')"
                    size="sm"
                    v-text="processing ? $t('Message.loading') : 'Add Article'"
                />
              </div>
            </div>
            <!-- <b-button
                        :disabled="processing"
                        size="md"
                        variant="success"
                        v-text="processing ? $t('Message.loading') : 'Update'"
                        @click="updateFeeType"
                    /> -->
          </div>
          <div v-if="!addArticle">
            <b-button
                v-if="markRemoveArticles.length > 0"
                @click="updateFeeType('REMOVE')"
                size="sm"
                variant="danger"
                v-text="processing ? $t('Message.loading') : 'Remove'"
            />
          </div>
        </ValidationObserver>
      </div>
    </CustomNoHeaderModalVue>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import bus from "@/bus";
import Paginate from "vuejs-paginate";
import useAppConfig from "@core/app-config/useAppConfig";
import vSelect from "vue-select";
import mdAccessDenied from "@core/components/permission/mdAccessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";

export default {
  name: "FeeType",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    CustomNoHeaderModalVue,
    paginate: Paginate,
    mdAccessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      items: {results: [], total_pages: 1},
      feeTypeForm: {
        add_golf_article_uuids: [],
        remove_golf_article_uuids: [],
        name: "",
        description: "",
      },
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['golf_related']['fees']))},
      feeTypePage: {
        limit: 20,
        pages: 1,
        page: 1
      },
      selectedArticles: [],
      markRemoveArticles: [],
      selectedData: {},
      markArticles: [],
      selected: false,
      accessDenied: false,
      selectedRemoved: false,
      loadingArticle: true,
      checked: "selected",
      mainArticles: {},
      addArticle: true,
      articlePage: 1,
      copyMainArticle: [],
      articleRemove: [],
      articleAdd: [],
      finalArticle: [],
      openModalFeeType: false,
      openCreateModalFeeType: false,
      processing: false,
      queryParams: {
        limit: 30,
      },
      articleParams: {
        limit: 10,
      },
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      fields: [
        {
          key: "eventId",
          label: "exportCard.table.eventId",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 10px",
            width: "90px",
          },
        },
        {
          key: "filename",
          label: "exportCard.table.fileName",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "status",
          label: "Message.tableHeader.status",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "70px",
          },
        },
        {
          key: "message",
          label: "Notification.message",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "80px",
          },
        },
      ],
      locale: {
        title: "CustomersCard.customerSegmentsTab",
        addSegment: "CustomersCard.addSegment",
        edit: "edit",
      },
      articleResults: [],
      emptyCate: "",
    };
  },
  computed: {
    message() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "swe_message" : "eng_message";
      } else return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    features() {
      if (this.GET_LOCALE === "") {
        return this.swedishLang ? "features" : "features";
      } else return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.getGolfArticles();
    this.getFeeType();
    this.disabledFields();
    bus.$on("open-modal-fee-type", (_) => {
      if (this.accessDenied === true) {
        return false
      }
      this.openCreateModalFeeType = true;
    });
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        hidden_object['hidden_fields']['golf_related']['fees'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {
      }
    },
    toggle(bool) {
      this.addArticle = bool;
      // if (bool) {
      //     this.finalArticle = this.articleAdd
      //     this.updateMainTableList(bool)
      // } else {
      //     this.finalArticle = this.articleRemove
      //     this.updateMainTableList(bool)
      // }
    },
    search: debounce(function (e) {
      if (e) {
        this.getFeeType({URL: "/?search=" + escape(e), method: "get"});
      } else {
        this.getFeeType();
      }
    }, 350),
    getFeeType(init = null) {
      const mode = init ? init : {URL: "/", method: "get"};
      this.accessDenied = false
      this.$useJwt.getFeesTypes(mode).then((res) => {
        this.items = res.data.data;
        if (res.data.data.count > this.feeTypePage.limit) {
          this.items.total_pages = Math.ceil(res.data.data.count / this.feeTypePage.limit)
        } else {
          this.items.total_pages = 1
        }

      }).catch(err => {
        if (err.response.status === 403) {
          this.accessDenied = true
        } else {
          this.accessDenied = false
        }
      });
    },
    searchArticleSelect(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteArticle(loading, search, this);
      }
    },
    searchRemoteArticle: debounce(function (loading, search, vm) {
      const query = {search: escape(search)};
      this.$useJwt
          .getFees({URL: "/", method: "get"}, {params: query})
          .then((res) => {
            this.articleResults = res.data.data.results;
            loading(false);
          })
          .catch((err) => {
          });
      //   this.$useJwt
      //     .getGolfArticles({ params: query })
      // .then((res) => {
      //   this.articleResults = res.data.data;
      //   loading(false);
      // })
      // .catch((err) => {});
    }, 350),
    setArticle(item) {
      const uuid = this.selectedArticles.filter((el) => el.uuid === item.uuid);
      if (uuid.length < 1) {
        this.selectedArticles.push(item);
      }
      this.emptyCate = "";
    },
    removeMember(index) {
      this.selectedArticles.splice(index, 1);
    },
    createFeeType() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.processing = true;
          const payload = {};
          payload.add_golf_article_uuids = [];
          payload.name = this.feeTypeForm.name;
          payload.description = this.feeTypeForm.description;

          payload["add_golf_article_uuids"] = this.selectedArticles.map(
              (el) => {
                return el.uuid;
              }
          );

          this.$useJwt
              .getFeesTypes({URL: "", method: "post"}, payload)
              .then((res) => {
                this.getFeeType();
                this.$refs.form.reset();
                this.openCreateModalFeeType = false;
                this.processing = false;
                this.clearFieldForm();
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.processing = false;
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.error,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                }
              });
        }
      });
    },
    updateFeeType(mode) {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.processing = true;
          const payload = this.feeTypeForm;
          if (mode === "ADD") {
            payload["remove_golf_article_uuids"] = [];
            payload["add_golf_article_uuids"] = this.markArticles.map((el) => {
              return el.uuid;
            });
          } else {
            payload["add_golf_article_uuids"] = [];
            payload["remove_golf_article_uuids"] = this.finalArticle.map(
                (el) => {
                  return el.uuid;
                }
            );
          }

          this.$useJwt
              .getFeesTypes(
                  {URL: `/${this.selectedData.uuid}`, method: "put"},
                  payload
              )
              .then((res) => {
                this.getFeeType();
                this.getGolfArticles();
                this.markRemoveArticles = [];
                this.mainArticles = [];
                this.openModalFeeType = false;
                this.processing = false;
                this.clearFieldForm();
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.processing = false;
                if (err.response.data.error) {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.error,
                      "AlertTriangleIcon",
                      "danger"
                  );
                } else {
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                }
              });
        }
      });
    },
    performAllChecked(e) {
      this.copyMainArticle.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
      this.verifyMarks();
    },
    performSingleChecked(e) {
      this.verifyMarks();
    },
    performAllRemoveChecked(e) {
      this.finalArticle.forEach((element) => {
        element.checkbox = this.selectedRemoved === "selected" ? true : false;
      });
      this.verifyRemoveMarks();
    },
    performSingleRemove() {
      this.verifyRemoveMarks();
    },
    verifyRemoveMarks() {
      const filter = this.finalArticle.filter((item) => item.checkbox);
      if (filter.length > 0) {
        this.markRemoveArticles = filter;
      } else {
        this.markRemoveArticles = [];
      }
    },
    updateMainTableList(bool) {
      let list = [];
      if (bool) {
        this.copyMainArticle.map((el) => {
          const filter = this.articleAdd.filter(
              (item) => item.uuid === el.uuid
          );
          if (filter.length < 1) {
            list.push(el);
          }
        });
        this.copyMainArticle = [...list];
      } else {
        this.copyMainArticle.map((el) => {
          const filter = this.articleRemove.filter(
              (item) => item.uuid === el.uuid
          );
          if (filter.length < 1) {
            list.push(el);
          }
        });
        this.copyMainArticle = [...list];
      }
      this.verifyMarks();
    },
    verifyMarks() {
      const filter = this.copyMainArticle.filter((item) => item.checkbox);
      if (filter.length > 0) {
        this.markArticles = filter;
      } else {
        this.markArticles = [];
      }
    },
    processArticle() {
      if (this.addArticle) {
        this.markArticles.map((el) => {
          const filter = this.articleAdd.filter(
              (element) => el.uuid === element.uuid
          );
          if (filter.length < 1) {
            this.articleAdd.push(el);
          }
        });
        this.finalArticle = this.articleAdd;
      } else {
        this.markArticles.map((el) => {
          const filter = this.articleRemove.filter(
              (element) => el.uuid === element.uuid
          );
          if (filter.length < 1) {
            this.articleRemove.push(el);
          }
        });
        this.finalArticle = this.articleRemove;
      }
      this.updateMainTableList(this.addArticle);
    },
    firePagination(number) {
      this.rticlePage = number;
      this.articleParams.page = number;
      this.getGolfArticles(true);
    },
    onChangePagination(number) {
      const mode = {URL: `/?limit=${this.feeTypePage.limit}&page=${number}`, method: "get"};

      this.$useJwt.getFeesTypes(mode).then((res) => {
        if (res.data.data.count > this.feeTypePage.limit) {
          this.items.total_pages = Math.ceil(res.data.data.count / this.feeTypePage.limit)
        } else {
          this.items.total_pages = 1
        }
        this.feeTypePage.page = number
        this.items.results = res.data.data.results;
      }).catch(err => {
      });
    },
    searchArticles: debounce(function (e) {
      if (e) {
        this.articleParams.search = e;
        this.getGolfArticles();
      } else {
        delete this.articleParams.search;
        this.getGolfArticles();
      }
    }, 350),
    getGolfArticles() {
      this.loadingArticle = true;
      this.$useJwt
          .getFees({URL: "/", method: "get"}, {params: this.articleParams})
          .then((res) => {
            this.loadingArticle = false;
            this.mainArticles = res.data;
            this.copyMainArticle = res.data.data.results.map((el) => {
              return {
                ...el,
                checkbox: false,
              };
            });
            // this.updateMainTableList(this.addArticle)
          });
    },
    openModalUpdate(data) {
      if (this.accessDenied === true) {
        return false
      }
      // this.articleRemove = []
      // this.articleAdd = []
      // this.finalArticle = []
      this.selectedData = data;
      this.finalArticle = [
        ...data.present_golf_articles.map((el) => {
          return {...el, checkbox: false};
        }),
      ];
      this.feeTypeForm.name = data.name;
      this.feeTypeForm.description = data.description;
      this.performArticleArrangement();
      this.openModalFeeType = true;
    },
    performArticleArrangement() {
      let array = [];
      this.mainArticles.data.results.map((el) => {
        const filter = this.finalArticle.filter(
            (item) => item.uuid === el.uuid
        );
        if (filter.length < 1) {
          array.push(el);
        }
      });
      this.copyMainArticle = array;
    },
    clearFieldForm() {
      this.articleAdd = [];
      this.articleRemove = [];
      this.feeTypeForm.add_golf_article_uuids = [];
      this.feeTypeForm.remove_golf_article_uuids = [];
      this.feeTypeForm.name = "";
      this.feeTypeForm.description = "";
      this.getGolfArticles();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table th {
  padding: 0.3rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  // padding-top: .4rem;
  padding: 0.3rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
}
</style>
