<template>
  <div>
    <div class="flex justify-between">
      <LittleTabs
          :tabs="tabs"
          :switchTabs="switchTabs"
          containerStyle="justify-content-start"
      />
      <div>
        <!-- {{ currentComponent }} -->
        <!-- <b-form-input v-if="currentComponent === 'FeesOverviewVue'"
            placeholder="Sök"
        /> -->
        <button @click="openCreateFeeModal" v-if="currentComponent === 'FeesTypeVue'"
                class="flex p-1 text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
          {{ $t(locale.addFeeType) }}
        </button>
      </div>
    </div>

    <KeepAlive>
      <transition name="zoom-fade" mode="out-in">
        <component class="mt-2" :is="currentComponent"/>
      </transition>
    </KeepAlive>
  </div>
</template>

<script>
import FeesOverviewVue from '@/@core/components/golfrelated/fees/FeesOverview.vue';
import FeesTypeVue from '@/@core/components/golfrelated/fees/FeesType.vue';
import bus from '@/bus';


export default {
  name: 'exports',
  components: {
    FeesOverviewVue,
    FeesTypeVue
  },
  data() {
    return {
      currentComponent: 'FeesOverviewVue',
      tabs: [
        {
          label: 'Fees.feesOverview',
          component: 'FeesOverviewVue',
          active: true,
        },
        {
          label: 'Fees.feeType',
          component: 'FeesTypeVue',
          active: false,
        },
      ],
      locale: {
        addFeeType: 'Fees.addFeeType'
      }
    };
  },

  methods: {
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;

      this.tabs[tabIndex].active = true;
    },
    openCreateFeeModal() {
      bus.$emit('open-modal-fee-type')
    },
    getUserSubscriptions() {
      this.$useJwt.subscription().catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      })
    }
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)

  },
};
</script>

<style></style>
